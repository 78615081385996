import {
  IonButtons,
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonIcon,
  IonFabButton,
  IonText,
  IonList,
  IonLabel,
  IonModal,
  IonButton,
  IonInput,
  IonLoading,
  IonRefresher,
  IonRefresherContent,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonFabList,
  IonSearchbar,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
  IonImg,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonAvatar,
  IonBadge,
  IonCheckbox,
  IonProgressBar,
  IonFooter,SearchbarCustomEvent, IonToggle, IonRadioGroup, IonRadio, IonCard
} from "@ionic/react";
import { v4 as uuidv4 } from "uuid";
import { RefresherEventDetail } from "@ionic/core";
import React, { useEffect, useState, useRef } from "react";
import "./Page.css";
import { IAction } from "./Page";

import {
  add,
  cube,
  menuOutline,
  search,
  reload,
  create,
  cash,
  send,
  arrowUp,
  arrowDown,
  trash,
  cloudUploadSharp, cloudDownloadSharp,
  speedometer,
  thumbsUp,
  thumbsDown,
  flashOff,
  camera,
  cameraOutline,shareOutline
} from "ionicons/icons";

import { InputPA, IPemakaian, uploadOne } from "../api/pemakaian-api";
import { useHistory } from "react-router";
import {
  getPemakaians,
  GetPemakaian,
  getSearchPemakaians,
  savePemakaian, savePemakaians, getAllPemakaians, exportExcel, clearPosteds
} from "../localdb/pemakaian-db";
import { getSummary, IProfile } from "../api/user-api";
import { GetRekening, saveRekening } from "../localdb/rekening-db";
import { caterDb } from "../localdb/initial";
import imageCompression from "browser-image-compression";
import { endpoint_img } from "../config/server-api";
import { uploadImage } from "../api/image-api";
import { useLocalStorage } from 'react-use';
import { useRecoilState, useRecoilValue } from "recoil";
import { isSearchState, searchTextState, pageSizeState } from "../data/store";
import { getPeriode } from "../utils/periodik";
import { get, set } from 'idb-keyval';
import { tokenState,authState } from "../data/store";

const Pemakaian: React.FC<IPemakaian & IAction> = (o: IPemakaian & IAction) => {

  return (
    <IonItem button onClick={o.edit}>
      <IonGrid>
        <IonRow>
          <IonCol class="ion-text-right" size="2">
            <IonText color="medium">{o.urutCatat}</IonText>
          </IonCol>

          <IonCol>
            <IonText>{o.nomorSambung}</IonText>

            <br></br>
            <IonText>{o.namaPelanggan}</IonText>

            <br></br>
            <IonText color="medium">{o.alamatPelanggan}</IonText>

            <br></br>
            <IonText color="light"> {o.jam} </IonText>
          </IonCol>

          <IonCol class="ion-text-center" size="3">
            {o.photo && (
              <IonAvatar>
                <IonImg src={URL.createObjectURL(o.photo)} />
              </IonAvatar>
            )}
            <IonTitle color="success">  {o.volumePemakaian}</IonTitle>
            {o.gantiMeter && (<IonIcon icon={speedometer} />)}
            {o.takTerjangkau && (<IonIcon icon={flashOff} />)}
          </IonCol>

        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

let allPemakaians: IPemakaian[] = [];

const Pemakaians: React.FC<IPemakaian> = () => {

  const [showDeleting, setShowDeleting] = useState(() => false);
  const [token, setToken] = useRecoilState(tokenState);
  const [searchText, setSearchText] = useRecoilState(searchTextState);
  const [isSearch, setIsSearch] = useRecoilState(isSearchState);
  const [pageSize, setPageSize] = useRecoilState(pageSizeState);
  const [pageCount, setPageCount] = useState(1)

  const [importing, setImporting] = useState(() => false);
  const [isUploading, setIsUploading] = useState(() => false);
  const [rekUp, setRekUp] = useState<IPemakaian>();
  const [totUp, setTotUp] = useState<number>(0);
  const [curUp, setCurUp] = useState<number>(0);
  const [startUp, setStartUp] = useState<number>(0);

  const [pemakaians, setPemakaians] = useState<IPemakaian[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);

  const [profile, setProfile] = useLocalStorage<IProfile>('profile')

  const [fileJSON, setFileJSON] = useState<File | null>(null);
  const [importSelesai, setImportSelesai] = useState(false);

  const [modeUpload, setModeUpload] = useState(() => false);
  const [mode, setMode] = useState(() => '3');

  async function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files !== null) {
      let uploaded = e.target.files[0];
      if (uploaded) {
        await setFileJSON(() => uploaded);
        console.log(uploaded);
      }
    }
  }

  const importData=async()=>{

    if (!fileJSON) return;

    let reader = new FileReader();
    reader.onload = await logFile;
    await reader.readAsText(fileJSON);
    await setImportSelesai(true);
    console.log("import selesai");
  }

  async function logFile (e:ProgressEvent<FileReader>) {
    e.preventDefault();
    let strJson = e.target!.result! as string;
    let paimports:IPemakaian[] = JSON.parse(strJson);
    let imports=paimports.map(n=>({...n, photo:null}));
    await savePemakaians(imports);

  }

  const refreshData = async () => {
    const reks = await getSearchPemakaians(searchText).then(data => allPemakaians = data);
    await setPemakaians(() => reks.slice(0, pageCount * pageSize));
    await setInfiniteDisabled(() => false);
  }

  const loadData = (ev: any) => {
    setTimeout(async () => {
      await setPageCount(pageCount + 1)
      await refreshData();
      ev.target.complete();
    }, 1000);
  }

  useIonViewWillEnter(async () => {
    await showDefault();
  });
  
  async function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    await showDefault();
    setTimeout(() => event.detail.complete(), 100);
  }
  
  async function showDefault() {
    await setPageCount(1);
    await refreshData();
    //setTimeout(() => searchRef?.current?.setFocus(), 100);
  }

  const startUpload = async () => {
    await setTotUp(() => 0);
    await setCurUp(() => 0);
    await setIsUploading(() => true);
    await setStartUp(()=>Date.now());
  }

  const finishUpload = async () => {
    await setTotUp(() => 0);
    await setCurUp(() => 0);
    await setIsUploading(() => false);
    await showDefault();
  }

  const uploadPA = async (uploadAll: boolean = true) => {

    // hapus terposting
    await clearPosteds();
    
    let unposteds = await allPemakaians.filter((n) => !n.posted).sort((a, b) => a.urutCatat - b.urutCatat);
    switch(mode) {
      case '1':
        unposteds=unposteds.slice(0,10);
        break;
      case '2':
        unposteds=pemakaians;
        break;
      default:
        // code block
    } 
    await setTotUp(unposteds.length);

    let urut = 1;
    try {
      await unposteds.forEach(async (n) => {

        if (uploadAll) {
            try {
            await uploadImage(n.nomorSambung, n.namaPelanggan, getPeriode(), n.photo,);
          } catch (e) {
            console.error(e);
          }
        }

        const pa: InputPA = {
          nomorSambung: n.nomorSambung,
          takTerjangkau: n.takTerjangkau,
          gantiMeter: n.gantiMeter,
          stanRusak: n.stanRusak,
          stanAngkat: n.stanAngkat,
          stanAkhir: n.stanAkhir,
          urutCatat: n.urutCatat,
          jam: n.jam
        }

        const req = await uploadOne(token!, pa);

        const res = req?.entryStan?.string;
        if (res === "berhasil") {
          const pa = await GetPemakaian(n.id);
          if (pa) {
            pa.posted = true;
            pa.photo = null;
            await savePemakaian(pa);
          }
          const rek = await GetRekening(n.id);
          if (rek) {
            rek.progress = 2;
            await saveRekening(rek);
          }
          await setCurUp(urut++);
          await setRekUp(pa);
        }
        else {
          await setCurUp(urut++);
          console.log('response->', res)
        }
      });
    } catch (e) {
      console.log(e)
    } finally {
      await setCurUp(unposteds.length);
      console.log('response->', 'gagal maning son')
    }
    await showDefault();
  };

  const exportData=async ()=>{
    await exportExcel(profile? profile.nama: '');
  }

  const showDeleteMessage = async () => await setShowDeleting(() => true);

  const okDelHandler = async () => {
    await clearData();
    await setShowDeleting(() => false);
  };

  const cancelDelHandler = async () => {
    await setShowDeleting(() => false);
  };

  const clearData = async () => {
    const db = await caterDb();
    await db.clear("pemakaians");
    await showDefault();
  };

  const history = useHistory();

  const editHandler = async (id: string) => {
    await history.push(`/pemakaians/${id}`);
  };

  useEffect(() => {
    setIsSearch(()=>true);
    showDefault();
  }, [searchText]);
   
  const onSearchClear = async () => {
    await showDefault();
    await setSearchText('');
  };
 
  const showSearch = async () => {
    await setIsSearch(true);
  };

  const hideSearch = async () => {
    await setIsSearch(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            Pemakaian ({pemakaians.length} of {allPemakaians.length})
          </IonTitle>
          {isUploading && curUp === totUp && totUp === 0 && (
            <IonToggle slot="end" checked={modeUpload} onIonChange={(e) => setModeUpload(e.detail.checked)} />
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>

        <IonList>
          {importing && (
            <>
            <br/>
            <IonTitle>Import Data PA</IonTitle>
            <br/>
            <IonCol size="8">
            <IonItem>
              <IonButton size="default" expand="block" color="primary" shape='round' fill='outline'>
                <label > Browse
                  <input type="file" id="file" onChange={handleUpload} accept=".json"/>
                </label>
              </IonButton>    
              </IonItem>
              <IonItem>
        
              <IonButton onClick={importData} size="default" expand="block" color="primary" shape='round' fill='outline'>
                Process
                </IonButton>
                </IonItem>

              {importSelesai && (
                <IonItem>
                <IonButton size="default" expand="block" color="success" shape='round' fill='outline' onClick={e=>{setImporting(false);setImportSelesai(false);showDefault();}}>
                Finish
                </IonButton>
                </IonItem>
              )}
              {!importSelesai && (
                <IonItem>
                  <IonButton size="default" expand="block" color="danger" shape='round' fill='outline' onClick={e=>setImporting(false)}>
                  Cancel
                </IonButton>
                </IonItem>
              )}
            </IonCol>

            </>
          )}

          {isUploading && (
            <IonItem>
              <IonCol class="ion-text-center">
                <img src="/assets/upload.png" />
                <br/>
                <IonTitle>UPLOAD PEMAKAIAN AIR</IonTitle>
                <br/>
                {curUp === totUp && totUp === 0 && (
                  <>
                    {modeUpload && (
                      <IonGrid>
                        <IonLabel color={modeUpload ? "dark" : "medium"} > Opsi Upload :</IonLabel>
                        <IonRadioGroup value={mode} onIonChange={e => setMode(e.detail.value)}>
                          <IonRow>
                            <IonCol>
                              <IonItem>
                                <IonRadio value='1' />&nbsp;
                                <IonLabel color={mode === '1' ? "dark" : "medium"}> Minimal (10 pelanggan)</IonLabel>
                              </IonItem>
                              <IonItem>
                                <IonRadio value='2' />&nbsp;
                                <IonLabel color={mode === '2' ? "dark" : "medium"}> Natural (sesuai yang ter-load)</IonLabel>
                              </IonItem>
                              <IonItem>
                                <IonRadio value='3' />&nbsp;
                                <IonLabel color={mode === '3' ? "dark" : "medium"}> Maximal (semua)</IonLabel>
                              </IonItem>
                            </IonCol>
                          </IonRow>
                        </IonRadioGroup>
                      </IonGrid>
                    )}

                    <IonText color="primary">Upload data PA plus gambar: </IonText>
                    <IonButton size="large" shape="round" fill="solid" color="success" onClick={async e => await uploadPA(true)}>
                      <IonIcon size="large" icon={cloudUploadSharp} /> &nbsp; Upload Full
                    </IonButton>
                    <br/>
                    <br/>
                    <IonText color="primary">Upload data PA saja (gunakan ini hanya saat UPLOAD FULL bermasalah): </IonText>
                    <IonButton size="default" shape="round" fill="outline" color="warning" onClick={async e => await uploadPA(false)}>
                      <IonIcon icon={cloudUploadSharp} /> &nbsp; Upload Data
                    </IonButton>
                    <br/>
                    <br/>
                    <IonText color="primary">Export data PA (format JSON). Gunakan ini hanya saat UPLOAD DATA bermasalah: </IonText>
                    <br/>
                    <IonButton size="default" shape="round" fill="outline" color="danger" onClick={exportData}>
                      <IonIcon icon={shareOutline} /> &nbsp; EXPORT Data
                    </IonButton>
                    <br/>
                    <br/>
                    <IonButton shape="round" size="default" color="danger" onClick={finishUpload}>Cancel</IonButton>
                  </>
                )}

                {totUp > 0 && (
                  <>
                    <br/>
                    <IonTitle>Progress : {(curUp / totUp * 100)?.toFixed(2)} %</IonTitle>
                    <br/>
                    <IonProgressBar color="success" value={curUp / totUp}></IonProgressBar>
                    <IonProgressBar color="success" value={curUp / totUp}></IonProgressBar>
                    <br/>
                  </>
                )}

                {curUp < totUp && totUp > 0 && (
                  <>
                    <br/>
                    <IonText color="warning"># {curUp} of {totUp} PA, sisa {totUp-curUp}.</IonText>
                    <br/>
                    <br/>
                    <IonText>{rekUp?.nomorSambung}</IonText>
                    <br/>
                    <IonText>{rekUp?.namaPelanggan}</IonText>
                    <br/>
                    <IonText>{rekUp?.alamatPelanggan}</IonText>
                    <br/>
                  </>
                )}

                {curUp === totUp && totUp > 0 && (
                  <>
                    <br/>
                    <IonText>Upload {totUp} PA selesai selama {((Date.now()-startUp)/60000).toFixed(1)} menit</IonText>
                    <br/>
                    <br/>
                    <IonButton size="large" shape="round" color="primary" onClick={finishUpload}>
                      <IonIcon icon={reload} /> &nbsp; Refresh
                    </IonButton>
                  </>
                )}
                <br/>
              </IonCol>
            </IonItem>
          )}

          {showDeleting && (
            <IonItem>
              <IonGrid>
                <IonRow class="ion-text-center">
                  <IonText>
                    Data pemakaian air akan dihapus dari perangkat, pemakaian yang belum diupload akan hilang. Lanjutkan?
                  </IonText>
                </IonRow>
                <IonRow>
                  <IonCol class="ion-text-center">
                    <IonButton shape="round" color="danger" expand="block" fill="outline" onClick={okDelHandler}>
                      Yes
                    </IonButton>
                  </IonCol>
                  <IonCol class="ion-text-center">
                    <IonButton shape="round" color="success" expand="block" fill="outline" onClick={cancelDelHandler}>
                      No
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          )}

          {!isUploading && !importing && pemakaians?.length > 0 && pemakaians?.map((c) => (
            <Pemakaian key={c.id} {...c} edit={() => editHandler(c.id)} />
          ))}

        </IonList>

        <IonInfiniteScroll threshold="100px" disabled={isInfiniteDisabled} onIonInfinite={(e: CustomEvent<void>) => loadData(e)}>
          <IonInfiniteScrollContent loadingSpinner="bubbles"/>
        </IonInfiniteScroll>
      </IonContent>

      <IonFooter>
        <IonContent>

          {isSearch && (
            <IonFab horizontal="start" vertical="bottom" slot="fixed">
              <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} onIonClear={onSearchClear} debounce={1000}/>
            </IonFab>
          )}

          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={hideSearch}>
              <IonIcon icon={menuOutline} />
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton>
                <IonIcon icon={trash} onClick={showDeleteMessage} />
              </IonFabButton>
              <IonFabButton onClick={showSearch}>
                <IonIcon icon={search} />
              </IonFabButton>
              <IonFabButton>
                <IonIcon icon={reload} onClick={showDefault} />
              </IonFabButton>
              <IonFabButton >
                <IonIcon icon={cloudDownloadSharp} color="danger" onClick={e=>setImporting(true)} />
              </IonFabButton>
              <IonFabButton >
                <IonIcon icon={cloudUploadSharp} color="success" onClick={startUpload} />
              </IonFabButton>
            </IonFabList>
          </IonFab>

        </IonContent>
      </IonFooter>
    </IonPage>
  );
};

export default Pemakaians;


