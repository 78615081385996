import { atom, RecoilState } from "recoil";
import { IProfile } from "../api/user-api";

export const profileState = atom<IProfile>({
  key: 'profile',
  default: {
    id:'',
    nama:'',
    email:'',
    kodeCabang: '',
    namaCabang:'',
    jumlahRekening: 0,
    uploaded: 0,
  }
});

export const pageSizeState = atom({
  key: 'pageSize',
  default: 50,
});

export const searchTextState = atom({
  key: 'searchText',
  default: '',
});

export const isSearchState = atom({
  key: 'isSearch',
  default: true
});

export const includeNewState = atom<boolean>({
  key: 'includeNew',
  default: true
});

export const tokenState = atom<string>({
  key: 'token',
  default: ''
});

export const authState = atom<boolean>({
  key: 'auth',
  default: false
});

