import { IPemakaian } from "../api/pemakaian-api";
import { caterDb } from "./initial";
import { getPeriode } from "../utils/periodik";
import exportFromJSON from 'export-from-json';

export const getAllPemakaians = async () => {
  const db = await caterDb();
  return await db.getAll("pemakaians");
};

export const getPemakaians = async () => {
  const pemakaians = await getAllPemakaians();
  return await pemakaians.filter(n => !n.posted).sort((a, b) => a.urutCatat - b.urutCatat);
};

export const getSearchPemakaians = async (cari: string = "") => {
  cari=cari.toLowerCase();
  const pemakaians = await getPemakaians();
  return await pemakaians.filter(n => n.nomorSambung.includes(cari)
    || n.namaPelanggan.toLowerCase().includes(cari)
    || n.alamatPelanggan.toLowerCase().includes(cari)
  );
};

export const clearData = async () => {
  const db = await caterDb();
  await db.clear("pemakaians");
};

export const clearPosteds = async () => {
  const db = await caterDb();
  const pemakaians = await getAllPemakaians();
  const todels= await pemakaians.filter(n => n.posted);
  await todels.forEach(async (n)=>{
    await db.delete('pemakaians', n.id);
  });
};

export const GetPemakaian = async (id: string) => {
  const db = await caterDb();
  return await db.get('pemakaians', id)!;
}

export const GetCount = async () => {
  const db = await caterDb();
  return await db.count('pemakaians')!;
}

export const savePemakaian = async (pa: IPemakaian) => {
  const db = await caterDb();
  return await db.put("pemakaians", pa, pa.id);
};

export const savePemakaians = async (pas: IPemakaian[]) => {
  await pas.forEach((pa) => savePemakaian(pa));
};

export const exportExcel=async(user:string)=>{
  const data = await getPemakaians();
  const fileName = `pa ${user} ${getPeriode()}`;
  const exportType =  exportFromJSON.types.json
  
  return await exportFromJSON({ data, fileName, exportType });
}

