import { openDB, DBSchema } from "idb";

interface Cater extends DBSchema {
  pemakaians: {
    value: {
      id: string;

      rekeningId: string;
      nomorSambung: string;
      namaPelanggan: string;
      alamatPelanggan: string;

      tanggal: string;
      jam: string;
      urutCatat: number;

      stanAwal:number;
      stanAkhir: number;
      takTerjangkau: boolean;

      gantiMeter: boolean;
      stanRusak: number;
      stanAngkat: number;

      volumePemakaian:number;

      filePath: string;
      photo:File|null;
      posted:boolean;
    };
    key: string;
    indexes: { "by-urutCatat": number };
  };

  rekenings: {
    value: {
      id: string;
      nomor: string;
      nama: string;
      alamat: string;
      dusun: string;
      cabang: string;
      karyawanId:string;
      golongan: string;
      kelas: string;
      tunggakan: number;
      urut: number;
      status: string;
      stanAwal:number;
      tanggal:string;
      progress:number
    };
    key: string;
    indexes: { "by-urut": number };
  };

  // keyvalue: {
  //   key: string;
  //   value: any
  // };
}

export const caterDb = async () => {
  const db = await openDB<Cater>("cater-db", 1, {
    upgrade(db) {
      db.createObjectStore("rekenings");
      db.createObjectStore("pemakaians");
      //db.createObjectStore("keyvalue");
    },
  });

  return db;
};


