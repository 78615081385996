import CryptoJS from "crypto-js";

const useEncryption = (key:string='tai123') => {
  const encryptData = (data:string) => {   
    return CryptoJS.AES.encrypt(data, key).toString();
  };

  const decryptData = (encryptedData:string) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  };
  return {
    encryptData,
    decryptData,
  };
};

export default useEncryption;