import {
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonText,
  IonLabel,
  IonButton,
  IonInput,
  IonLoading,
  IonButtons,
  IonBackButton,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import { v4 as uuidv4 } from 'uuid'
import React, { useEffect, useState, useRef } from 'react'
import './Page.css'

import { useHistory, useParams } from 'react-router'
import { ParamTypes } from '../utils/paramsTypes'
import { IRekening } from '../api/rekening-api'

export const RekeningEdit: React.FC<IRekening> = () => {

  const [namaPelanggan, setNamaPelanggan] = useState('')
  const [alamatPelanggan, setAlamatPelanggan] = useState('')
  const [kelasId, setKelasId] = useState('')
  const [diameter, setDiameter] = useState(0)

  const [error, setError] = useState('')

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Edit Rekening</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/rekenings" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Nama Pelanggan</IonLabel>
                <IonInput
                  type="text"
                  value={namaPelanggan}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Alamat Pelanggan</IonLabel>
                <IonInput
                  type="text"
                  value={alamatPelanggan}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Kelas</IonLabel>
                <IonInput
                  type="text"
                  value={kelasId}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Diameter</IonLabel>
                <IonInput
                  type="text"
                  value={diameter}
                />
              </IonItem>
            </IonCol>
          </IonRow>

          {error && (
            <IonRow className="ion-text-center">
              <IonCol>
                <IonText color="danger">
                  <p>{error}</p>
                </IonText>
              </IonCol>
            </IonRow>
          )}

        </IonGrid>
      </IonContent>
    </IonPage>
  )
}
