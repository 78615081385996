import { gql } from "graphql-request";
import { client, endpoint } from "../config/server-api";

export interface IPemakaian {
  id: string

  rekeningId: string
  nomorSambung: string
  namaPelanggan: string
  alamatPelanggan: string

  tanggal: string
  jam: string
  urutCatat: number
  stanAwal:number
  stanAkhir:  number
  takTerjangkau: boolean

  gantiMeter:boolean
  stanRusak:number
  stanAngkat:number
  volumePemakaian:number
  filePath:string
  photo: File | null
  posted:boolean
}

export interface InputPA{
  nomorSambung: string,
  takTerjangkau: boolean,
  gantiMeter: boolean,
  stanRusak: number,
  stanAngkat: number,
  stanAkhir: number,
  urutCatat: number,
  jam: string
};

export const uploadOne = async (token: string, pa: InputPA) => {
  var query = gql`mutation {
    entryStan(
      input: {
        nomorSambung: "${pa.nomorSambung}", 
        takTerjangkau: ${pa.takTerjangkau}, 
        gantiMeter: ${pa.gantiMeter}, 
        stanRusak: ${pa.stanRusak}, 
        stanAngkat: ${pa.stanAngkat}, 
        stanAkhir: ${pa.stanAkhir},
        urutCatat: ${pa.urutCatat},
        jam: "${pa.jam}"
      }
    ) {
      string
    }
  }`;

  await client.setHeaders({authorization : `Bearer ${token}`});
  return await client.request(query);
};
