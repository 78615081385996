import {
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonText,
  IonLabel,
  IonButton,
  IonInput,
  IonLoading,
  IonButtons,
  IonBackButton,
  IonDatetime,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonCheckbox,
  IonImg,
  IonIcon,
  IonFabButton,
  IonFabList,
  IonCard,
  IonToggle,
  IonAvatar,
  IonFab,
  IonFooter,
  IonRadioGroup,
  IonRadio,
} from "@ionic/react";
import ReactCrop, { Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import imageCompression from "browser-image-compression";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState, useRef } from "react";
import "./Page.css";

import { IPemakaian } from "../api/pemakaian-api";

import { useHistory, useLocation, useParams } from "react-router";
import { ParamTypes } from "../utils/paramsTypes";
import { DateTime } from "luxon";

import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { CameraResultType, CameraSource, Camera } from "@capacitor/camera";
import { camera, cameraOutline, cameraReverse, cameraSharp, remove, trash, arrowBackOutline, squareOutline, crop, arrowUndo, arrowRedo } from "ionicons/icons";
import { GetRekening, saveRekening } from "../localdb/rekening-db";
import { GetCount, GetPemakaian, savePemakaian } from "../localdb/pemakaian-db";
import { IRekening } from "../api/rekening-api";
import { endpoint_img } from "../config/server-api";
import { getPeriode } from "../utils/periodik";
import { getCropped, getFile, imgPreview } from "../picture/imgPreview";
import { useDebounceEffect } from "../picture/useDebounceEffect";
import { canvasPreview } from "../picture/canvasPreview";
let pa: IPemakaian | undefined;


export const PemakaianEdit: React.FC<IPemakaian> = () => {

  const [rekeningId, setRekeningId] = useState("");
  const [nomorSambung, setNomorSambung] = useState("");
  const [namaPelanggan, setNamaPelanggan] = useState("");
  const [alamatPelanggan, setAlamatPelanggan] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [urut, setUrut] = useState(0);
  const [stanAwal, setStanAwal] = useState(0);
  const [stanAkhir, setStanAkhir] = useState(0);
  const [volumePemakaian, setVolumePemakaian] = useState(0);
  const [takTerjangkau, setTakTerjangkau] = useState(() => false);
  const [alasan, setAlasan] = useState(() => '1');
  const [gantiMeter, setGantiMeter] = useState(() => false);
  const [stanRusak, setStanRusak] = useState(0);
  const [stanAngkat, setStanAngkat] = useState(0);

  const [imageSrc, setImageSrc] = useState("");
  const [imageMeter, setImageMeter] = useState<File | null>(null);
  const [previewSrc, setPreviewSrc] = useState("");
  const [previewMeter, setPreviewMeter] = useState<File | null>(null);

  const [filePath, setFilePath] = useState("");
  const [pixelCrop, setPixelCrop] = useState<PixelCrop>({ unit: 'px', x: 50, y: 50, width: 100, height: 100 });
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [posted, setPosted] = useState(() => false);

  const stanAkhirRef = useRef<HTMLIonInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  let { id } = useParams<ParamTypes>();
  console.log(id);
  let location = useLocation();
  const newEntry = location.pathname.includes('new');

  async function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files !== null) {
      let uploaded = e.target.files[0];
      if (uploaded) {
        await setImageMeter(() => uploaded);
        await setImageSrc(() => URL.createObjectURL(uploaded));
        await setPreviewMeter(null);
        await setPreviewSrc("");
      }
    }
  }

  const cropPicture = async () => {
    if (imageRef.current ) {
      let crop=completedCrop??pixelCrop;
      const cropped = await getCropped(imageRef.current!, crop!, scale, rotate);
      await setPreviewSrc(await URL.createObjectURL(cropped));
      const file = await getFile(cropped, `${nomorSambung}-${getPeriode()}.jpeg`);
      await setPreviewMeter(() => file);
    }
  };

  const rotateRight = async () => {
    if (rotate<=360){
      setRotate(()=>rotate+10)
    }
  };

  const rotateLeft = async () => {
    if (rotate>=10){
      setRotate(()=>rotate-10)
    }
  };

  const setDefaultCrop = async () => {
    setCompletedCrop(undefined);
    setRotate(0);
    setScale(1);
    await setPreviewMeter(null);
    await setPreviewSrc("");
  };

  const delPicture = async () => {
    await setImageMeter(null);
    await setImageSrc("");
  };

  useEffect(() => {
    if (newEntry) {
      const getOne = async () => {
        const rek = await GetRekening(id);
        //console.log(rek);
        if (rek) {
          await setRekeningId(id);
          await setNomorSambung(rek.nomor);
          await setNamaPelanggan(rek.nama);
          await setAlamatPelanggan(rek.alamat);
          await setStanAwal(rek.stanAwal);
          await setStanAkhir(rek.stanAwal);
        }
      };
      delPicture();
      getOne();
    }
    else {
      const loadOne = async () => {
        pa = await GetPemakaian(id);
        if (pa) {
          await setRekeningId(id);
          await setNomorSambung(pa.nomorSambung);
          await setNamaPelanggan(pa.namaPelanggan);
          await setAlamatPelanggan(pa.alamatPelanggan);

          await setTakTerjangkau(pa.takTerjangkau);
          await setGantiMeter(pa.gantiMeter);

          await setStanAwal(pa.stanAwal);
          await setStanRusak(pa.stanRusak);
          await setStanAngkat(pa.stanAngkat);
          await setStanAkhir(pa.stanAkhir);

          if (pa.photo) {
            await setImageMeter(pa.photo);
            const imgUrl = URL.createObjectURL(pa.photo);
            await setImageSrc(imgUrl);
            await setPixelCrop({ unit: 'px', x: 10, y: 10, width: 180, height: 180 });
          }
        }
      };
      loadOne();
    }
    setTimeout(() => stanAkhirRef?.current?.setFocus(), 100);
  }, [id]);

  useEffect(() => {
    const updatePa = async () => {
      if (newEntry) {
        if (gantiMeter) {
          await setStanRusak(stanAwal);
          await setStanAngkat(0);
          await setStanAkhir(0);
        } else {
          await setStanRusak(0);
          await setStanAngkat(0);
          await setStanAkhir(stanAwal);
        }
      } else {
        if (pa) {
          if (gantiMeter) {
            if (pa.gantiMeter) {
              await setStanRusak(pa.stanRusak);
              await setStanAngkat(pa.stanAngkat);
              await setStanAkhir(pa.stanAkhir);
            } else {
              await setStanRusak(pa.stanAkhir);
              await setStanAngkat(0);
              await setStanAkhir(0);
            }
          } else {
            await setStanRusak(0);
            await setStanAngkat(0);
            await setStanAkhir(pa.stanAwal + pa.volumePemakaian);
          }
        }
      }
    }
    updatePa();
  }, [gantiMeter]);

  useEffect(() => {
    const updateVol = async () => {
      if (gantiMeter) {
        await setVolumePemakaian(() => (stanRusak - stanAwal) + (stanAkhir - stanAngkat));
      }
      else {
        await setVolumePemakaian(() => (stanAkhir - stanAwal));
      }
    }
    updateVol();
  }, [stanRusak, stanAngkat, stanAkhir]);

  const history = useHistory();

  const [error, setError] = useState("");
  const [isPosting, setIsPosting] = useState(() => false);

  const saveHandler = async () => {

    if (!rekeningId) {
      await setError("Isi data yang lengkap");
    } else {

      await setIsPosting(() => true);

      let tgl: string = DateTime.local().toISODate().substring(0, 10);
      let waktu: string = DateTime.local().toISO().substring(0, 19);
      let urt: number = await GetCount() + 1;

      const pa = await GetPemakaian(id);
      if (pa) {
        tgl = pa.tanggal;
        waktu = pa.jam;
        urt = pa.urutCatat;
      }

      const pemakaian: IPemakaian = {
        id: id,
        rekeningId,
        nomorSambung,
        namaPelanggan,
        alamatPelanggan,

        tanggal: tgl,
        jam: waktu,
        urutCatat: urt,
        stanAwal,
        stanAkhir,
        takTerjangkau,

        gantiMeter,
        stanRusak,
        stanAngkat,
        volumePemakaian,
        filePath,
        photo: previewMeter ?? imageMeter,
        posted,
      };
      try {
        const res = await savePemakaian(pemakaian);
        const rek = await GetRekening(id);
        if (rek) {
          rek.progress = 1;
          await saveRekening(rek);
        }

        await goBack();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const cancelHandler = async () => {
    await goBack();
  };

  const goBack = async () => {
    if (newEntry) {
      await history.replace("/rekenings");
    } else {
      await history.replace("/pemakaians");
    }
  }

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonTitle>{newEntry ? '(New) Pemakaian Air' : '(Edit) Pemakaian Air'}</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={goBack} >
              <IonIcon icon={arrowBackOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol >
              <IonItem>
                <IonLabel color="warning" position="stacked" >{nomorSambung}</IonLabel>
                <IonInput color="dark" type="text" readonly value={namaPelanggan} />
              </IonItem>

              <IonItem>
                <IonLabel color={takTerjangkau ? "dark" : "medium"} >Tak Terjangkau</IonLabel>
                <IonToggle checked={takTerjangkau} onIonChange={(e) => setTakTerjangkau(e.detail.checked)} />
              </IonItem>
              {takTerjangkau && (
                <IonRadioGroup value={alasan} onIonChange={e => setAlasan(e.detail.value)}>
                  <IonRow>
                    <IonCol>
                      <IonItem>
                        <IonRadio value='1' />&nbsp;
                        <IonLabel color={alasan === '1' ? "dark" : "medium"}>Baik</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonRadio value='2' />&nbsp;
                        <IonLabel color={alasan === '2' ? "dark" : "medium"}> Buram</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonRadio value='3' />&nbsp;
                        <IonLabel color={alasan === '3' ? "dark" : "medium"}> Mati</IonLabel>
                      </IonItem>
                    </IonCol>
                    <IonCol>
                      <IonItem>
                        <IonRadio value='4' />&nbsp;
                        <IonLabel color={alasan === '4' ? "dark" : "medium"}> Terpendam</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonRadio value='5' />&nbsp;
                        <IonLabel color={alasan === '5' ? "dark" : "medium"}> Terpagar</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonRadio value='6' />&nbsp;
                        <IonLabel color={alasan === '6' ? "dark" : "medium"}> Indoor</IonLabel>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonRadioGroup>
              )}
              <IonItem>
                <IonLabel color={gantiMeter ? "dark" : "medium"} >Ganti Meter</IonLabel>
                <IonToggle checked={gantiMeter} 
                  onIonChange={(e) => {
                    setGantiMeter(e.detail.checked);
                    stanAkhirRef?.current?.setFocus();
                  }}/>
              </IonItem>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel color="medium" position="floating" >Stan Awal</IonLabel>
                    <IonInput color="medium" type="number" readonly value={stanAwal} />
                  </IonItem>
                  {gantiMeter && (
                    <IonItem>
                      <IonLabel color="medium" position="floating" >Stan Rusak</IonLabel>
                      <IonInput inputMode="tel"
                        color="primary"
                        type="number"
                        value={stanRusak}
                        onIonChange={async (e) =>
                          setStanRusak(parseFloat(e.detail.value! ?? stanAwal))
                        }
                      />
                    </IonItem>
                  )}
                </IonCol>
                <IonCol>
                  {gantiMeter && (
                    <IonItem>
                      <IonLabel color="medium" position="floating" >Stan Baru</IonLabel>
                      <IonInput inputMode="tel"
                        color="primary"
                        type="number"
                        value={stanAngkat}
                        onIonChange={(e) =>
                          setStanAngkat(parseFloat(e.detail.value! ?? 0))
                        }
                      />
                    </IonItem>
                  )}

                  <IonItem>
                    <IonLabel color="medium" position="floating" >Stan Akhir</IonLabel>
                    <IonInput inputMode="tel"
                      color="primary"
                      type="number"
                      value={stanAkhir}
                      ref={stanAkhirRef}
                      onIonChange={(e) => setStanAkhir(parseFloat(e.detail.value! ?? stanAwal))}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonItem>
                <IonLabel color={volumePemakaian < 0 ? "danger" : (volumePemakaian === 0 ? "warning" : "dark")} slot="start">Volume PA</IonLabel>
                <IonTitle color={volumePemakaian < 0 ? "danger" : (volumePemakaian === 0 ? "warning" : "dark")} slot="end" >{volumePemakaian} </IonTitle>
              </IonItem>
            </IonCol>
          </IonRow>

          {error && (
            <IonRow class="ion-text-center">
              <IonCol>
                <IonText color="danger">
                  <p>{error}</p>
                </IonText>
              </IonCol>
            </IonRow>
          )}

          {imageSrc.length > 10 && (
            <IonItem >
              <IonCol class="ion-text-center">
                <ReactCrop aspect={1} minWidth={100} minHeight={100} crop={pixelCrop}
                 onChange={(c, _) => {setPixelCrop(c);stanAkhirRef?.current?.setFocus();}} 
                 onComplete={(c) => setCompletedCrop(c)}>
                  <img ref={imageRef} className="image-meter" src={imageSrc}  />
                </ReactCrop>
              </IonCol>

              <IonCol size='5' class="ion-text-center">
                <IonRow >
                  <IonCol class="ion-text-center">
                    <img className="image-crop" alt="cropped preview"  src={previewSrc.length>10? previewSrc : "" } />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol class="ion-text-center">
                    <IonButton shape="round" fill="outline" onClick={rotateLeft}>
                      <IonIcon color="medium" icon={arrowUndo} />
                    </IonButton>
                    <IonButton shape="round" fill="outline" onClick={rotateRight}>
                      <IonIcon color="medium" icon={arrowRedo} />
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol class="ion-text-center">
                    <IonButton shape="round" fill="outline" onClick={cropPicture}>
                      <IonIcon color="medium" icon={crop} />
                    </IonButton>
                    <IonButton shape="round" fill="outline" onClick={setDefaultCrop}>
                      <IonIcon color="medium" icon={squareOutline} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonItem>
          )}

          <br></br>
          <IonLoading isOpen={isPosting} onDidDismiss={() => setIsPosting(false)} message={"saving..."} duration={500}/>
        </IonGrid>

        <IonGrid>
          <IonRow class="ion-text-center">
          <IonCol />
          <IonCol size="4.5" class="ion-text-center">
              <IonButton shape="round" color="danger" expand="block" fill="solid" onClick={cancelHandler}>
                Cancel
              </IonButton>
            </IonCol>
            <IonCol size="2" class="ion-text-center">
              <IonFab horizontal="center" >
                {imageSrc.length === 0 ?
                  <IonFabButton color="primary">
                    <label className="custom-file-upload">
                      <input onChange={handleUpload} className="form-control" type="file" accept="image/*" name="formFile" capture/>
                      <IonIcon size="large" icon={cameraOutline}/>
                    </label>
                  </IonFabButton>
                  :
                  <IonFabButton color="warning" onClick={delPicture}>
                    <IonIcon size="large" icon={trash}></IonIcon>
                  </IonFabButton>
                }
              </IonFab>
            </IonCol>
            <IonCol size="4.5" class="ion-text-center">
              <IonButton shape="round" color="success" expand="block" fill="solid" onClick={saveHandler} disabled={Number.isNaN(volumePemakaian) || volumePemakaian < -100}>
                OK
              </IonButton>
            </IonCol>
            <IonCol />

          </IonRow>
        </IonGrid>

      </IonContent>

    </IonPage>
  );
};
