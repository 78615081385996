import {
  IonContent,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react'

import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import {
  cart,
  cash,
  arrowUndo,
  person,
  airplane,
  home,
  cube,
  copy,
  logOut,
  logIn,
  people,
} from 'ionicons/icons'

import './Menu.css'
import { useLocalStorage } from 'react-use';
import { IProfile, isTokenValid } from '../api/user-api'
import { get, set } from 'idb-keyval';
import { useRecoilState, useRecoilValue } from "recoil";
import { tokenState,authState } from "../data/store";

const Menu: React.FC = () => {
  const [token, setToken] = useRecoilState(tokenState);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authState);
  const [passwordHash, setPasswordHash] = useLocalStorage('passwordHash', '');

  const history = useHistory()

  const logOff = async () => {
    setToken('');
    setPasswordHash('');
  }

  useEffect(() => {
    const check=async()=>{
      const isValidToken=await isTokenValid(token);
      setIsAuthenticated(isValidToken);
    }
    setTimeout(()=>check(), 1000);
  }, [token]);

  useEffect(() => {
    setInterval(async()=>{
      const isValidToken=await isTokenValid(token);
      setIsAuthenticated(isValidToken);
    }, 1000*60*60*12); //sehari
  }, []);

  useEffect(() => {
    const goHome=async()=>{
      await history.replace('/home');
    }
    goHome();
  }, [isAuthenticated]);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonMenuToggle autoHide={false}>
          <IonList id="menuList">
            <IonItem routerLink="/home" detail={true}>
              <IonIcon slot="start" icon={home} />
              <IonLabel>Home</IonLabel>
            </IonItem>
            {isAuthenticated && (
              <>
                <IonItem routerLink="/pemakaians" detail={true}>
                  <IonIcon slot="start" icon={cash} />
                  <IonLabel>Pemakaian</IonLabel>
                </IonItem>
                <IonItem routerLink="/rekenings" detail={true}>
                  <IonIcon slot="start" icon={person} />
                  <IonLabel>Rekening</IonLabel>
                </IonItem>
                <IonItem button onClick={logOff}>
                  <IonIcon slot="start" icon={logOut} />
                  <IonLabel>Exit</IonLabel>
                </IonItem>
              </>
            )}
            {!isAuthenticated && (
                <IonItem routerLink="/login" detail={true}>
                  <IonIcon slot="start" icon={logIn} />
                  <IonLabel>Login</IonLabel>
                </IonItem>
            )}
          </IonList>
        </IonMenuToggle>
      </IonContent>
    </IonMenu>
  )
}

export default Menu
