import { PixelCrop } from 'react-image-crop'
import { canvasPreview } from './canvasPreview'

let previewUrl = ''

async function toBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return await new Promise((resolve) => {
    canvas.toBlob(resolve as BlobCallback)
  })
}

export async function getCropped(
  image: HTMLImageElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0,
) {
  const canvas = document.createElement('canvas')
  canvasPreview(image, canvas, crop, scale, rotate)
  return await toBlob(canvas)
}

// Returns an image source you should set to state and pass
// `{previewSrc && <img alt="Crop preview" src={previewSrc} />}`
export async function imgPreview(blob:Blob) {
  if (previewUrl) {
    URL.revokeObjectURL(previewUrl)
  }
  previewUrl = URL.createObjectURL(blob)
  return previewUrl
}

export async function getFile(blob:Blob, name:string) {
  return await new File([blob], name,{type:blob.type});
}
