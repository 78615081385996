import imageCompression from "browser-image-compression";
import { endpoint_img } from "../config/server-api";

export const uploadImage = async (nomor: string, nama: string, periode: string, photo: File | null) => {
  if (photo) {

    const compressOptions = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 300,
      useWebWorker: true
    }

    try{
      const compressedFile = await imageCompression(photo, compressOptions);
      var newfile = new File([compressedFile], `${nomor}-${periode}.jpeg`, {type:compressedFile.type}); 
  
      let formData = new FormData();
      formData.append("nomor", nomor);
      formData.append("nama", nama);
      formData.append("periode", periode);
      formData.append("photo", newfile);
  
      //var data = await fetch(endpoint_img, {
      await fetch(endpoint_img, {
        method: "POST",
        body: formData,
      });//.then((res) => res.json())
    }catch(e){
      console.log(e);
    }

    //return data;
  }
};
