import {Sunburst} from 'react-vis';
import {rekap, profile, } from '../api/user-api'

const total=rekap?.map(n=>n.total).reduce((a,b)=>a+b,0);
const terbaca=rekap?.map(n=>n.terbaca).reduce((a,b)=>a+b,0);
const minimal=rekap?.map(n=>n.terbaca/n.total).reduce((a,b)=>a<b? a:b, 1);
const maximal=rekap?.map(n=>n.terbaca/n.total).reduce((a,b)=>a>b? a:b, 0);

const dataRekap={
  title:"PDAM",
  label:"PDAM",
  color: "green",
  size:100,
  style:{strokeWidth: 2, stroke: 'white'},
  children: rekap.map((r, i)=>({
    title:"Cabang",
    color: (r.kodeCabang===profile?.kodeCabang? "goldenrod" :  (r.terbaca/r.total<=minimal? "brown": (r.terbaca/r.total>=maximal? "green" : (i%2===0? "darkgray": "gray")))),
    label: r.kodeCabang, // + " " + r.total===0? 0 : (r.terbaca/r.total*100).toFixed()+"%",
    labelStyle: {fontSize: 12}, 
    dontRotateLabel:false,
    children:[
      {title:'terbaca', size:r.terbaca, color: (r.kodeCabang===profile?.kodeCabang? "whitesmoke" :  (r.terbaca/r.total<=minimal? "brown": (r.terbaca/r.total>=maximal? "green" : (i%2===0? "gray": "dimgray"))))}, 
      {title:'sisa', size:r.sisa, color:'transparent'}
    ]
  }))
}

function Hint(value){
  return <p>{value}</p>
}

export function RekapChart(){
  return <Sunburst hideRootNode colorType="literal" data={dataRekap} height={300} width={350}>
  </Sunburst>
}

