import { IRekening } from "../api/rekening-api";
import { caterDb } from "./initial";

export const getRekenings = async () => {
  const db = await caterDb();
  const rekenings: IRekening[] = await db.getAll("rekenings");
  return await rekenings
    .sort((a, b) => (a.urut < b.urut ? -1 : 1))
    .filter(n => !n.progress);
};

export const getSearchRekenings = async (cari: string = "", includeNew=true) => {
  cari=cari.toLowerCase();
  const rekenings: IRekening[] = await getRekenings();
  const all = await rekenings
  .filter(n => n.nomor.includes(cari)
    || n.nama.toLowerCase().includes(cari)
    || n.alamat.toLowerCase().includes(cari));
    
  return includeNew? all : all.filter(n=>n.urut>0);
};

export const getVirtualRekenings = async (cari: string = "", index=0) => {
  const rekenings: IRekening[] = await getSearchRekenings(cari);
  return await rekenings.slice(index, index+50)
};

export const getCountRekenings = async (cari: string = "") => {
  const rekenings: IRekening[] = await getSearchRekenings(cari);
  return await rekenings.length;
};

export const GetRekening = async (id: string) => {
  const db = await caterDb();
  return await db.get('rekenings', id)!;
}

export const saveRekenings = async (reks: IRekening[]) => {
  await reks.forEach((r) => saveRekening(r));
};

export const saveRekening = async (rek: IRekening) => {
  const db = await caterDb();
  return await db.put('rekenings', rek, rek.id)!;
}