import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonText,
  IonBackButton,
  IonLoading,
} from "@ionic/react";

import React, { useRef, useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import "./Page.css";
import { person, key } from "ionicons/icons";
import useEncryption from "../utils/useEncryption";

import { getRekapCabang, getSummary, IProfile, IRekapCabang, signin } from "../api/user-api";
import { useLocalStorage } from 'react-use';
import { get, set } from 'idb-keyval';
import { useRecoilState, useRecoilValue } from "recoil";
import { tokenState,authState } from "../data/store";

const Login: React.FC = () => {

  const [email, setEmail] = useLocalStorage('email', '');
  const [password, setPassword] = useState('');

  const [token, setToken] = useRecoilState(tokenState);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authState);
  
  const [profile, setProfile] = useLocalStorage<IProfile>('profile')
  const [rekap, setRekap] = useLocalStorage<IRekapCabang[]>('rekap', [])
  
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  const [passwordHash, setPasswordHash] = useLocalStorage('passwordHash', '')
  const { encryptData, decryptData } = useEncryption();

  const history = useHistory();

  useEffect(()=>{
    setPassword(decryptData(passwordHash!));
  },[])

  const onOK = async () => {

    setIsLoading(true);
    await setPasswordHash(encryptData(password!));
    
    try {
      var res = await signin(email!, password!);     
      const jwt: string = res?.login?.token;
      console.log('JWT', jwt);

      if (jwt.length>10 ) {

        await setToken(jwt);
        await setIsAuthenticated(true);

        //summary
        var profile = await getSummary(jwt);
        if (profile) {
          await setProfile(profile);
        }

        var rekCabs=await getRekapCabang(jwt);
        if (rekCabs){
          await setRekap(rekCabs);
        }

        await history.replace("/home");
      } else {
        await setError("Mungkin email atau password salah");
      }
    } catch (error) {
      await setError("Mungkin jaringan atau server down");
      console.error();
    }
    setIsLoading(false);
  };

  const onCancel = async () => {
    await history.replace("/home");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow class="ion-text-center">
            <IonCol>
              <br />
              <img src="/assets/login.png" />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol class="ion-text-center">
              <IonItem>
                <IonIcon icon={person} slot="start" />
                <IonLabel color="medium" position="stacked">Email</IonLabel>
                <IonInput color="success" type="text" value={email} onIonChange={e => setEmail(e.detail.value!)} />
              </IonItem>
              <IonItem>
                <IonIcon icon={key} slot="start" />
                <IonLabel color="medium" position="stacked">Password</IonLabel>
                <IonInput color="success" type="password" value={password} onIonChange={e => setPassword(e.detail.value!)} />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow class="ion-text-center">
            <IonCol/>
            <IonCol size="4.8" class="ion-text-center" >
              <IonButton shape="round" color="danger" expand="block" fill="solid" onClick={onCancel} >
                Cancel
              </IonButton>
            </IonCol>
            <IonCol size="4.8" class="ion-text-center" >
              <IonButton shape="round" color="success" expand="block" fill="solid" onClick={onOK} >
                OK
              </IonButton>
              </IonCol>
          </IonRow>

          {error && (
            <IonRow className="ion-text-center">
              <IonCol>
                <IonText color="danger">
                  <p>{error}</p>
                </IonText>
              </IonCol>
            </IonRow>
          )}

          <IonLoading isOpen={isLoading} onDidDismiss={() => setIsLoading(false)} message={"Waiting for authentication..."} duration={30000} />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
