import Menu from './components/Menu'
import React from 'react'
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Redirect, Route } from 'react-router-dom'
import { setupIonicReact } from '@ionic/react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import 'react-image-crop/dist/ReactCrop.css'
import '../node_modules/react-vis/dist/style.css';
//import {XYPlot, LineSeries} from 'react-vis';

import Home from './pages/Home'
import Rekenings from './pages/Rekenings'
import Pemakaians from './pages/Pemakaians'
import Login from './pages/Login'

import { RekeningEdit } from './pages/RekeningEdit'
import { PemakaianEdit } from './pages/PemakaianEdit'

setupIonicReact({
  mode: 'md'
});

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <React.Suspense fallback={<div>Loading...</div>}>
          <IonApp>
            <IonReactRouter>
              <IonSplitPane contentId="main">
                <Menu />
                <IonRouterOutlet id="main">
                  <Route path="/home" component={Home} exact />
                  <Route path="/login" component={Login} />

                  <Route path="/rekenings" component={Rekenings} exact />
                  <Route path="/rekenings/:id" component={RekeningEdit} exact />

                  <Route path="/pemakaians" component={Pemakaians} exact />
                  <Route path="/pemakaians/:id" component={PemakaianEdit} exact />
                  <Route path="/pemakaians/new/:id" component={PemakaianEdit} exact />

                  <Redirect from="/" to="/home" exact />
                </IonRouterOutlet>
              </IonSplitPane>
            </IonReactRouter>
          </IonApp>
      </React.Suspense>
    </RecoilRoot>
  )
}

export default App
