import {
  IonButtons,
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonIcon,
  IonFabButton,
  IonText,
  IonList,
  IonLoading,
  IonRefresher,
  IonRefresherContent,
  IonFabList,
  IonSearchbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonModal,
  IonButton,
  IonCard,
  useIonViewDidEnter,
  useIonViewWillEnter,
  IonAvatar,
  IonBadge,
  IonProgressBar,
  IonFooter,
  IonToggle, SearchbarCustomEvent
}
from "@ionic/react";

import { v4 as uuidv4 } from "uuid";
import { RefresherEventDetail } from "@ionic/core";
import React, { useEffect, useState, useRef } from "react";
import "./Page.css";
import { IAction } from "./Page";

import {
  menuOutline,
  search,
  reload,
  create,
  trash,
  download,
  arrowUp,
  arrowDown,
  arrowUpOutline,
  arrowUpCircle,
  cloudDownloadSharp,
  thumbsDown,
  thumbsUp,
  checkmarkCircleOutline,
  checkboxOutline,
  closeCircleOutline,
  checkmark,
  power,
} from "ionicons/icons";

import { useHistory } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import { IRekening, downloadRekenings } from "../api/rekening-api";

import {
  getSearchRekenings,
  saveRekening,
} from "../localdb/rekening-db";
import { caterDb } from "../localdb/initial";
import { Console } from "console";
import { Virtuoso } from "react-virtuoso";
import { useLocalStorage } from 'react-use';
import { isSearchState, searchTextState, pageSizeState, includeNewState} from "../data/store";
import { get, set } from 'idb-keyval';
import { tokenState,authState } from "../data/store";

const Rekening: React.FC<IRekening & IAction> = (n: IRekening & IAction) => {
  return (
    <IonItem button onClick={n.edit}>
      <IonGrid>
        <IonRow>
          <IonCol class="ion-text-center" size="2">
            {n.urut === 0 ?
              <IonBadge color="warning">New</IonBadge>
              :
              <>
                <IonText color="dark" >{n.urut}</IonText>
                <br></br>
                <IonText color="medium">{n.tanggal?.substring(8, 10)}</IonText>
              </>
            }
          </IonCol>
          <IonCol>
            <IonText color="dark">{n.nomor}</IonText>
            <br></br>
            <IonText color="dark">{n.nama}</IonText>
            <br></br>
            <IonText color="medium">{n.alamat}</IonText>
          </IonCol>
          <IonCol class="ion-text-center" size="1.5">
            {n.tunggakan > 1 &&
              <IonButton shape="round" 
              color={n.tunggakan === 2 ? "success" : (n.tunggakan===3? "warning" : "danger")} expand="block" 
              fill="outline">
                <IonText color="dark"> {n.tunggakan} </IonText>
              </IonButton>}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

let allRekenings: IRekening[] = [];

const Rekenings: React.FC = () => {

  const [showDeleting, setShowDeleting] = useState(false);
  const [token, setToken] = useRecoilState(tokenState);
  const [searchText, setSearchText] = useRecoilState(searchTextState);
  const [isSearch, setIsSearch] = useRecoilState(isSearchState);
  const [includeNew, setIncludeNew] = useRecoilState(includeNewState);

  const [pageSize, setPageSize] = useRecoilState(pageSizeState);
  const [pageCount, setPageCount] = useState(1)

  const [isDownloading, setIsDownloading] = useState(false);
  const [rekDown, setRekDown] = useState<IRekening>();
  const [totDown, setTotDown] = useState<number>(0);
  const [curDown, setCurDown] = useState<number>(0);  
  const [startDown, setStartDown] = useState<number>(0);

  const [rekenings, setRekenings] = useState<IRekening[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);

  const refreshData = async () => {
    const reks = await getSearchRekenings(searchText, includeNew).then(data => allRekenings = data);
    await setRekenings(() => reks.slice(0, pageCount * pageSize));
    await setInfiniteDisabled(() => false);
  }

  const loadData = (ev: any) => {
    setTimeout(async () => {
      await setPageCount(pageCount + 1)
      await refreshData();
      ev.target.complete();
    }, 1000);
  }

  useIonViewWillEnter(async () => {
    await showDefault();
  });
  
  async function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    await showDefault();
    setTimeout(() => event.detail.complete(), 100);
  }
  
  async function showDefault() {
    await setPageCount(1);
    await refreshData();
  }

  const startDownload = async () => {
    await setTotDown(() => 0);
    await setCurDown(() => 0);
    await setIsDownloading(() => true);
    await setStartDown(()=>Date.now());
  }

  const finishDownload = async () => {
    await setTotDown(() => 0);
    await setCurDown(() => 0);

    await setSearchText('');
    await setIsSearch(true);

    await setIsDownloading(() => false);
    await showDefault();
  }

  const downloadData = async () => {
  
    await clearData();
    console.log('token', token);
    const req = await downloadRekenings(token!);
    const rekenings: IRekening[] = req.pelanggans.nodes;

    if (rekenings.length > 0) {

      await setTotDown(rekenings.length);
      let curr = 1;
      await rekenings.forEach(async r => {
        await saveRekening(r);
        await setRekDown(r);
        await setCurDown(curr);
        curr++;
      });
    }
  };

  const showDeleteMessage = () => setShowDeleting(() => true);

  const okDelHandler = async () => {
    await clearData();
    await setShowDeleting(() => false);
    await showDefault();
  };

  const cancelDelHandler = async () => {
    await setShowDeleting(() => false);
  };

  const clearData = async () => {
    const db = await caterDb();
    await db.clear("rekenings");
    await showDefault();
  };

  const history = useHistory();

  const editHandler = async (id: string) => {
    await history.replace(`/pemakaians/new/${id}`);
  };

  useEffect(() => {
    setIsSearch(()=>true);
    showDefault();
  }, [searchText, includeNew]);
  
  const onSearchClear = async () => {
    await setSearchText('');
    showDefault();
  };

  const showSearch = async () => {
    await setIsSearch(true);
  };

  const hideSearch = async () => {
    await setIsSearch(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            Rekening ({rekenings.length} of {allRekenings.length})
          </IonTitle>
          <IonToggle slot="end" checked={includeNew!} onIonChange={(e) => setIncludeNew(e.detail.checked!)}/>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>

        <IonList>
          {isDownloading && (
            <IonItem>
                <IonCol class="ion-text-center">

                  <img src="/assets/download.png" />
                  <br />
                  <br />
                  <IonTitle>DOWNLOAD PELANGGAN</IonTitle>
                  <br></br>
                  {curDown === totDown && totDown === 0 && (
                    <>
                      <IonText color="primary">Download/refresh data pelanggan yang belum diupload PA-nya: </IonText>
                      <IonButton size="large" shape="round" fill="solid" color="success" onClick={async e => await downloadData()}>
                        <IonIcon size="large" icon={cloudDownloadSharp} /> &nbsp; Start Download
                      </IonButton>
                      <br></br>
                      <br></br>
                      <IonButton shape="round" size="default" color="danger" onClick={finishDownload}>Cancel</IonButton>
                      <br/>
                    </>
                  )}

                  {curDown<totDown && totDown > 0 && (
                    <>
                      <IonTitle>Progress : {(curDown / totDown * 100)?.toFixed(2)} %</IonTitle>
                      <br/>
                      <IonProgressBar color="success" value={curDown / totDown}></IonProgressBar>
                      <IonProgressBar color="success" value={curDown / totDown}></IonProgressBar>
                      <br/>
                      <IonText color='warning'># {curDown} of {totDown} pelanggan, sisa {totDown-curDown}.</IonText>
                      <br/>
                      <br/>
                      <IonText>{rekDown?.nomor}</IonText>
                      <br/>
                      <IonText>{rekDown?.nama}</IonText>
                      <br/>
                      <IonText>{rekDown?.alamat}</IonText>
                      <br/>
                    </>
                  )}

                  {curDown === totDown && totDown > 0 && (
                    <>
                      <br/>
                      <IonText>Download {totDown} pelanggan selesai selama {((Date.now()-startDown)/60000).toFixed(1)} menit</IonText>
                      <br/>
                      <br/>
                      <IonButton size="large" shape="round" color="primary" onClick={finishDownload}>
                        <IonIcon icon={reload} /> &nbsp; Refresh
                      </IonButton>
                    </>
                  )}
                </IonCol>
            </IonItem>
          )}

          {showDeleting && (
            <IonItem>
              <IonCol>
                <IonRow >
                  <IonCol class="ion-text-center">
                    <img src="/assets/reset.png" />
                    <br />
                    <IonText>
                      Data rekening pelanggan akan dihapus dari perangkat, download ulang untuk melihatnya. Lanjutkan?
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton size="large" shape="round" color="warning" expand="block" fill="clear" onClick={okDelHandler}>
                      Yes
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton size="large" shape="round" color="success" expand="block" fill="clear" onClick={cancelDelHandler}>
                      No
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonItem>
          )}

          {!isDownloading && rekenings?.length > 0 && rekenings?.map((c) => (
            <Rekening key={c.id} {...c} edit={() => editHandler(c.id)} />
          ))}
        </IonList>

        <IonInfiniteScroll threshold="100px" disabled={isInfiniteDisabled} onIonInfinite={(e: CustomEvent<void>) => loadData(e)}>
          <IonInfiniteScrollContent loadingSpinner="bubbles"/>
        </IonInfiniteScroll>
      </IonContent>

      <IonFooter>
        <IonContent>
          {isSearch && (
            <IonFab horizontal="start" vertical="bottom" slot="fixed" >
              <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} onIonClear={onSearchClear} debounce={1000}/>
            </IonFab>
          )}

          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={hideSearch}>
              <IonIcon icon={menuOutline} />
            </IonFabButton>

            <IonFabList side="top">
              <IonFabButton>
                <IonIcon icon={trash} onClick={showDeleteMessage} />
              </IonFabButton>
              <IonFabButton onClick={showSearch}>
                <IonIcon icon={search} />
              </IonFabButton>
              <IonFabButton>
                <IonIcon icon={reload} onClick={e=>showDefault()} />
              </IonFabButton>
              <IonFabButton>
                <IonIcon icon={cloudDownloadSharp} onClick={e=>startDownload()} />
              </IonFabButton>
            </IonFabList>
          </IonFab>

        </IonContent>
      </IonFooter>
    </IonPage>
  );
};

export default Rekenings;

