import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonIcon,
  IonFabButton,
  IonGrid,
  IonCol,
  IonRow,
  IonLabel,
  IonCard,
  IonText,
  IonImg,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,IonProgressBar,
  IonAvatar,
  IonItem,
  IonInput,
  IonFooter,
  IonBadge,
} from "@ionic/react";
import { v4 as uuidv4 } from "uuid";
import React, { useRef, useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import { PieChart } from "../components/Chart";
import "./Page.css";
import { add, logIn, key, logOut, cart, people } from "ionicons/icons";
import { useLocalStorage } from 'react-use';
import { useRecoilState, useRecoilValue } from "recoil";
import { isTokenValid, getSummary, IProfile, IRekapCabang } from "../api/user-api";
import { getPeriode } from "../utils/periodik";
import { profileState } from "../data/store";
import { RekapChart } from "../components/Rekap";
import { get, set } from 'idb-keyval';
import { tokenState,authState } from "../data/store";

const Home: React.FC = () => {

  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authState);
  const [profile, setProfile] = useLocalStorage<IProfile>('profile');
  const [rekap, setRekap] = useLocalStorage<IRekapCabang[]>('rekap', []);
  
  const periode = getPeriode().substring(0, 4) + '-' + getPeriode().substring(4);
  
  const total=rekap?.map(n=>n.total).reduce((a,b)=>a+b,0);
  const terbaca=rekap?.map(n=>n.terbaca).reduce((a,b)=>a+b,0);
  const minimal=rekap?.map(n=>n.terbaca/n.total).reduce((a,b)=>a<b? a:b, 1);
  const maximal=rekap?.map(n=>n.terbaca/n.total).reduce((a,b)=>a>b? a:b, 0);
  
  const [release, setRelease] = useState(false);
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{isAuthenticated ? 'Profile' : 'PDAM WONOSOBO'}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {isAuthenticated && (
          <>
            <IonGrid>
              <IonRow class="ion-text-center">
                <IonCol >
                  <img src="/assets/user.png" />
                  <br></br>
                  <IonItem >
                    <IonLabel color="warning" position="stacked">{profile?.nama}</IonLabel>
                    <IonText>{profile?.email}</IonText>
                  </IonItem>
                  <IonItem>
                    <IonLabel color="warning" position="stacked">Cabang {profile?.namaCabang}</IonLabel>
                    <IonText>Periode {profile ? periode : '-'} </IonText>
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow >
                <IonCol class="ion-text-right">
                  <IonRow class="ion-text-right">
                  <IonCol/>
                  <IonCol class="ion-text-left">
                    <IonLabel color="medium" >Total</IonLabel> <br/>
                    <IonTitle  >{profile?.jumlahRekening.toLocaleString()} </IonTitle>
                  </IonCol>
                  <IonCol class="ion-text-left">
                    <IonLabel color="medium" >Uploaded</IonLabel> <br/>
                    <IonTitle  >{profile?.uploaded.toLocaleString()} </IonTitle>
                  </IonCol>
                  <IonCol class="ion-text-left">
                    <IonLabel color="medium" >Sisa</IonLabel><br/>
                    <IonTitle >{(profile?.jumlahRekening! - profile?.uploaded!).toLocaleString()} </IonTitle>
                  </IonCol>
                  </IonRow>
                  {profile && (
                    <IonItem>
                      <br />
                      <IonProgressBar color="success" value={(profile?.jumlahRekening>0 ?  profile?.uploaded/profile?.jumlahRekening : 0)}></IonProgressBar> &nbsp;
                      <IonButton shape="round" expand="block" fill="outline" size="default" >
                      <IonTitle color="warning" > {(profile?.jumlahRekening>0 ?  profile?.uploaded/profile?.jumlahRekening*100 : 0).toFixed()}%</IonTitle>
                      </IonButton>
                      <br/>
                    </IonItem>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton routerLink="/rekenings">
                <IonIcon icon={people} />
              </IonFabButton>
            </IonFab>
          </>
        )}
          <IonGrid>
            <IonRow>
              <IonCol class="ion-text-center">
                <br/>
                <IonText>Progress Baca Meter Global</IonText>
                <br/>
                <IonTitle color="warning">{(total!>0? (terbaca!/total!*100).toFixed(2) : 0)} %</IonTitle>
                {rekap && (<RekapChart />)}
              </IonCol>
            </IonRow>
            <IonRow >
              <IonCol class="ion-text-center">
                {rekap && rekap?.map(r=>(
                  <>
                    <IonBadge key={r.kodeCabang} color={r.kodeCabang===profile?.kodeCabang? "dark" : r.terbaca/r.total<=minimal!? "danger": r.terbaca/r.total>=maximal!? "success":"medium"}>
                      {r.namaCabang} {r.total>0? (r.terbaca/r.total*100).toFixed():0} %
                    </IonBadge> &nbsp;
                  </>
                ))}
                <br/>
                <br/>
                <br/>
              </IonCol>
            </IonRow>
          </IonGrid>

          {!isAuthenticated && (
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton color="primary" routerLink="/login">
                <IonIcon color="dark" size="large" icon={logIn} />
              </IonFabButton>
            </IonFab>
          )}
      </IonContent>

      {release && (
        <IonCard>
          <IonTitle>Release Notes</IonTitle>
          <ul>
            <IonText> 22.1.3.19 Virtual List pelanggan</IonText><br/>
            <IonText> 22.1.3.20 Sticky Search</IonText><br/>
            <IonText> 22.1.3.21 Proses upload PA interaktif</IonText><br/>
            <IonText> 22.1.3.22 Proses download pelanggan interaktif</IonText><br/>
            <IonText> 22.1.4.11 Add Crop Photo WM</IonText><br/>
            <IonText> 22.1.4.12 Add chart Baca Meter Global</IonText><br/>
            <IonText> 22.1.4.13 Auto focus Stan Akhir</IonText><br/>
            <IonText> 22.1.4.14 Add Release Notes</IonText><br/>
            <IonText> 22.1.4.15 Autofill login credential</IonText><br/>
            <IonText> 22.1.4.16 Fix searching</IonText><br/>
            <IonText> 22.1.4.18 Add export/import pa</IonText><br/>
            <IonText> 22.1.4.19 fix download/upload</IonText><br/>
            <IonText> 22.1.4.20 stylish search</IonText><br/>
            <IonText> 22.1.4.21 use SSL (HTTPS)</IonText><br/>
            <IonText> 22.1.4.22 use image upload SSL (HTTPS)</IonText><br/>
            <IonText> 22.1.5.0 back to surge</IonText><br/>
            <IonText> 22.1.5.1 fix upload PA</IonText><br/>
            <IonText> 22.1.5.2 fix upload image</IonText><br/>
            <IonText> 22.1.5.3 add opsi upload</IonText><br/>
            <IonText> 22.1.6.0 Auto delete PA terupload</IonText><br/>
            <IonText> 22.1.6.1 ganti ip</IonText><br/>
          </ul>
        </IonCard>
      )}

      <IonFooter>
        <IonGrid>
          <IonRow >
            <IonCol class="ion-text-right">
              <IonButton shape='round' size="small" fill="clear" onClick={e=>setRelease(!release)}>
                <IonText color="primary" >Cater 22.1.6.1</IonText>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </IonPage>
  );
};

export default Home;
