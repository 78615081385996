import { request, gql } from "graphql-request";
import { client, endpoint } from "../config/server-api";
import jwt_decode from "jwt-decode";
import { get, set } from 'idb-keyval';

export interface IRekapCabang {
  kodeCabang: string;
  namaCabang:string;
  total: number;
  terbaca: number;
  sisa: number;
}

export interface IProfile {
  id: string;
  nama: string;
  email: string;
  kodeCabang: string;
  namaCabang:string;
  jumlahRekening: number;
  uploaded: number;
}

export const signin = async (_email: string, _password: string) => {
  var query = gql`mutation {
    login(input: {email: "${_email}", password: "${_password}"}) {token}
  }`;
  await client.setHeaders({});
  return await client.request(query);
};

export const getRekapCabang = async (token:string) => {
  var query = gql`query {
      rekapCabangs {
        nodes {
          kodeCabang
          namaCabang
          total
          terbaca    
          sisa
        }
      }
    }`;
    await client.setHeaders({authorization : `Bearer ${token}`});
    return await client.request(query).then(res=>res.rekapCabangs.nodes);
};

export const rekap:IRekapCabang[]|[] = localStorage.getItem('rekap')? JSON.parse(localStorage.getItem('rekap')!): [];
export const profile:IProfile|null = localStorage.getItem('profile')? JSON.parse(localStorage.getItem('profile')!): null;

export const getSummary = async (token:string) => {
  var query = gql`mutation {
    getSummary(input: {}) {
      results {
        id
        nama
        email
        kodeCabang
        namaCabang
        jumlahRekening
        uploaded
      }
    }
  }`;
  await client.setHeaders({authorization : `Bearer ${token}`});
  return await client.request(query).then(res=>res.getSummary.results[0])
};

interface jwt{
  nama:string;
  email:string;
  exp:number;
  iat:number;
  role:string;
}

export const isTokenValid = async(token:string='')=>{
  let valid=false;
  if (token?.length>10){
    const decoded:jwt=jwt_decode(token!);
    console.log('decoded', decoded);
    const {exp, iat}=decoded;
    const expired = exp*1000;
    valid=Date.now()<=expired;
    if (valid){
      console.log('jwt issued at', new Date(iat*1000));
      console.log('jwt expired at', new Date(expired));
    }else{
      console.log('not authenticated not authorized');
    }
  }
  return valid;
}