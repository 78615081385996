import { request, GraphQLClient } from 'graphql-request';

export const endpoint = "http://36.92.198.58:2500/cater-api";
export const endpoint_img = "http://36.92.198.58:2600/cater-api-image";
export const img_uploaded = "http://36.92.198.58:2600/uploads";

// export const endpoint = "https://api.spdt.my.id:4000/cater-api";
// export const endpoint_img = "https://api.spdt.my.id:2643/cater-api-image";
// export const img_uploaded = "https://api.spdt.my.id:2643/uploads";

export const client = new GraphQLClient(endpoint, { headers: {}});

