
import { request, gql } from "graphql-request";
import { client, endpoint } from "../config/server-api";

export interface IRekening {
  id: string
  nomor: string
  nama: string
  alamat: string
  dusun:string
  cabang: string;
  karyawanId: string;
  golongan:string
  kelas: string
  tunggakan:number
  urut:number
  status:string
  stanAwal:number
  tanggal: string
  progress:number //tgl upload bulan lalu
}

export const downloadRekenings = async (token:string) => {
  var query = gql`query {
    pelanggans {
      nodes {
        id
        nomor
        nama
        alamat
        dusun
        cabang
        karyawanId
        golongan
        kelas
        tunggakan
        urut
        status
        stanAwal
        tanggal
      }
    }
  }`;
  await client.setHeaders({authorization : `Bearer ${token}`});
  return await client.request(query);
};
